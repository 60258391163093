<template>
  <div>
    <p class="uppercase tracking-wider text-2xl mb-2">Banner Utama</p>
    <img
      :src="imgSrc"
      alt="profile picture"
      id="profile-picture"
      class="shadow-md rounded mx-auto"
    />
    <image-uploader
      ref="imageUploader"
      text="Unggah"
      :ratio="16 / 9"
      @ready="triggerUploadImage"
    ></image-uploader>
  </div>
</template>

<style lang="scss" scoped>
.profile-picture__action {
  @apply w-full bottom-0 left-0 p-2 grid grid-cols-6 gap-x-1;

  .upload-label {
    @apply text-white flex items-center justify-center;
    @apply bg-gray-900 bg-opacity-60;
    @apply rounded p-2;

    &:hover {
      @apply cursor-pointer;
    }
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      isProcessing: false,
    };
  },
  methods: {
    async triggerUploadImage(file) {
      const formData = new FormData();
      formData.append("image", file);
      await this.storeProfilePicture(formData);

      // reload image
      const reader = new FileReader();
      reader.onload = function (e) {
        let imageContainer = document.getElementById("profile-picture");
        imageContainer.src = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    ...mapActions("school_profile", [
      "storeProfilePicture",
      "fetchProfilePicture",
    ]),
  },
  computed: {
    imgSrc() {
      return this.profile_picture_url;
    },
    ...mapGetters("school_profile", ["profile_picture_url"]),
  },
  mounted() {
    this.fetchProfilePicture();
  },
  beforeDestroy() {
    this.$refs.imageUploader.$destroy();
  },
  name: "ProfilePicture",
  components: {
    ImageUploader: () => import("@/components/Common/ImageUploader.vue"),
  },
};
</script>